<template>
  <div class="m-goods">
    <div class="m-goods-img">
      <a :href="href || 'javascript:'" :target="href ? '_blank' : ''">
        <el-image style="width: 70px; height: 70px; border-radius: 4px" :src="img" fit="cover">
          <template #error>
            <div class="image-slot">
              <img src="@/assets/img/goodserro.png" alt="" />
            </div>
          </template>
        </el-image>
      </a>
    </div>
    <div class="m-goods-detail">
      <div
        class="m-goods-detail-wrap m-omit"
        v-for="detail in goodsDetail"
        :key="detail"
        v-html="detail"
      ></div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import { isPlainObjct } from '@/tools';
export default {
  name: 'MGoods',
  props: {
    img: {
      required: true,
    },
    detail: {
      type: Array,
      required: true,
    },
    href: {
      type: String,
    },
  },
  setup(props) {
    const goodsDetail = computed(() => {
      // 传入的 detail 为相应格式时（{text: '', type: ''}）进行以下处理
      if (!props.detail || !props.detail.length) {
        throw new Error('没有传入必要的props —— detail');
      }
      return props.detail.map(item => {
        const { text, type, href } = item;
        if (isPlainObjct(item)) {
          if (type) {
            const typeArr = type.split(',');

            const classNames = typeArr
              .map(item => {
                return `m-${item}`;
              })
              .join(' ');

            if (typeArr.indexOf('link') >= 0) {
              return `<a class="${classNames} m-omit" href="${href}" target="_blank" style="display: inline-block;" title="${text}">${text}</a>`;
            } else {
              return `<span class="${classNames}">${text}</span>`;
            }
          }
          return `<span>${text}</span>`;
        }
        return `<span>${item}</span>`;
      });
    });

    return {
      goodsDetail,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool';
.m-goods {
  display: flex;
  .m-goods-img {
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
  }
  .m-goods-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    width: calc(100% - 80px);
  }
}
</style>
